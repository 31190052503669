import React, { useEffect } from 'react'
import { motion, useAnimation, } from "framer-motion"
import { getMaskStaticPathWithAspectRatio } from "../../utils/staticPathsForMaskImages"
import { configCircleAndAroundCirclesPathsD, svgCirclePathDRelativeToCenter, svgPathsDRelativeToCenterOfCirclesAround, svgPathsRelativeToCenterOfOuterCircleAndSmallCirclesAround, SvgWithPathsOfOuterCircleAndSmallCirclesAround } from "../../utils/calculateSvgPath"

const mapImgStyle = {
    fullImgWidthInFullPageWidthAndFullImgHeightDeformed: {
        objectFit: 'fill',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0'
    },
    fullImgWidthInFullPageWidthAndProperAmountOfImgForHeight: {
        objectFit: 'cover',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0'
    },
    fullAvailableWidth: {
        // objectFit: 'cover',
        // width: '100%',
    }
}
const ImageWithCircleAndCirclesAroundClipPathAnimation = ({
    nameOfPaths = "circleAndCirclesAround",
    clipPathId = "imgCirclesAroundId",
    imgNamedStyle = "fullAvailableWidth",
    srcImg,
    imgMaskName = "mask3",
    diameter = 100,
    pointsNumber = 10,
    outerCirclePercentage = 0.5,
    distanceAroundCircleFromCenterPercentage = 0.8,
    radiusAroundCircle = 0.12,
    imgMaxHeight = "unset",
    isAnimation = true,
    isFitMaskHeightToImg = true,
    isFitMaskWidthToImg = true,
    widthWrap = "auto",
    heightWrap = "auto",
    styleWrap = {},
    imgHeight = "100%",
}) => {
    const ctrlOuterCircle = useAnimation()
    const ctrlAroundCircle = useAnimation()
    const ctrlImg = useAnimation()
    const pathsD = configCircleAndAroundCirclesPathsD[ nameOfPaths ];
    // console.log("ImageWithCircleAndCirclesAroundClipPathAnimation pathsD", nameOfPaths, pathsD)
    const { dOuterCircle, dsCirclesAround, dOuterCircleStart, dsCirclesAroundStart } = pathsD
    // const dOuterCircle = svgCirclePathDRelativeToCenter({ diameter, outerCirclePercentage })
    // const dsCirclesAround = svgPathsDRelativeToCenterOfCirclesAround({ diameter, pointsNumber, distanceAroundCircleFromCenterPercentage, radiusAroundCircle })
    // console.log("ImageWithCircleAndCirclesAroundClipPathAnimation dsCirclesAround", dsCirclesAround)
    // console.log("ImageWithCircleAndCirclesAroundClipPathAnimation dsCirclesAroundStart", dsCirclesAroundStart)
    const isMaskAnimation = (isFitMaskHeightToImg && isFitMaskWidthToImg) ? false : isAnimation
    const maskSizeWidthEnd = isFitMaskWidthToImg ? "auto" : "100%"
    const maskSizeHeightEnd = isFitMaskHeightToImg ? "auto" : "100%"
    useEffect(() => {
        if (!isAnimation) return
        const seq1 = async () => {

            ctrlAroundCircle.start(({ i }) => {
                // console.log("ImageWithCircleAndCirclesAroundClipPathAnimation ctrlAroundCircle.start i", i)
                // console.log("ImageWithCircleAndCirclesAroundClipPathAnimation ctrlAroundCircle.start dsCirclesAround[ i ]", dsCirclesAround[ i ])
                return ({
                    d: dsCirclesAround[ i ],
                    // fillOpacity: 0,
                    // backgroundSize:"100%",
                    transition: {
                        // type: "spring", stiffness: 100,
                        duration: 1,
                        // ease: "easeInOut",
                        delay: i
                    }
                })
            }
            )

            ctrlOuterCircle.start(() => ({
                d: dOuterCircle,
                // fillOpacity: 0,
                transition: {
                    type: "spring", stiffness: 100,
                    // duration: 2,
                    // ease: "easeInOut",
                    delay: pointsNumber
                }
            })
            )
            // ctrlImg.start(() => ({
            //     // fillOpacity: 0,
            //     backgroundSize: "100%",
            //     transition: {
            //         // type: "spring", stiffness: 100,
            //         duration: 2,
            //         ease: "easeInOut",
            //         delay: pointsNumber + 2

            //     }
            // }))
            // ctrlImg.start(() => ({

            //     backgroundImage: 'radial-gradient(circle at 50% 50%, #580303 0%, #530101 25%, transparent 75%, transparent 100%)',
            //     transition: {
            //         // type: "spring", stiffness: 100,
            //         duration: 0.5,
            //         ease: "easeInOut",
            //         delay: pointsNumber + 2

            //     }
            // }))
            isMaskAnimation && ctrlImg.start(() => ({

                // maskPosition: "50%",
                maskSize: `${maskSizeWidthEnd} ${maskSizeHeightEnd}`, //"100%",

                transition: {
                    // type: "spring", stiffness: 100,
                    duration: 1,
                    ease: "easeInOut",
                    delay: pointsNumber + 2

                }
            }))
        }
        seq1()
        return () => {
            ctrlAroundCircle.stop()
            ctrlImg.stop()
        }
    }, [ isAnimation, isMaskAnimation, ctrlImg, ctrlAroundCircle, ctrlOuterCircle, dOuterCircle, dsCirclesAround, pointsNumber ])

    const staticMaskImgPath = getMaskStaticPathWithAspectRatio(imgMaskName).path
    // console.log("ImageWithCircleAndCirclesAroundClipPathAnimation staticMaskImgPath", staticMaskImgPath)
    const usedDsAround = isAnimation ? dsCirclesAroundStart : dsCirclesAround
    // console.log("ImageWithCircleAndCirclesAroundClipPathAnimation usedDsAround", usedDsAround)
    // const { dOuterCircle, dsCirclesAround } = svgPathsRelativeToCenterOfOuterCircleAndSmallCirclesAround()
    // const dOuterCircleStart = svgCirclePathDRelativeToCenter({ diameter, outerCirclePercentage: 0 })
    // const dsCirclesAroundStart = svgPathsDRelativeToCenterOfCirclesAround({ diameter, pointsNumber, distanceAroundCircleFromCenterPercentage: 0, radiusAroundCircle: 0 })
    // console.log("dOuterCircle", dOuterCircle)
    // console.log("dsCirclesAround", dsCirclesAround)
    // const mask = isImgMaskRadialGradient ? 'radial-gradient(circle at 50% 50%, black 0%, black 25%, transparent 75%, transparent 100%)' : "unset"
    // console.log("isMaskRadialGradient", isImgMaskRadialGradient)
    // console.log("mask", mask)

    const maskSizeHeightStart = isFitMaskHeightToImg ? "auto" : "33%"
    const maskSizeWidthStart = isFitMaskWidthToImg ? "auto" : "33%"
    const imgStyle = mapImgStyle[ imgNamedStyle ]

    return (
        <div position="relative" style={{ width: widthWrap, height: heightWrap, ...styleWrap }}>
            <svg
                width="100" height="100"
                viewBox="0 0 100 100"
                style={{ position: 'absolute' }}
            >
                <defs>
                    <clipPath id={clipPathId}
                        clipPathUnits="objectBoundingBox"
                    >
                        <motion.path
                            // d={clipPathFrom}
                            d={isAnimation ? dOuterCircleStart : dOuterCircle}
                            animate={ctrlOuterCircle}
                            // fill="#FFFFFF"
                            // fill="#000000"
                            stroke="#000000" strokeMiterlimit="10"
                            strokeWidth="50"
                        // style={{
                        //     fillOpacity: 0.5,
                        //     fill: "black"
                        // }}
                        />
                        {usedDsAround.map((d, i) => (
                            <motion.path
                                key={`around-${i}`}
                                d={d}
                                animate={ctrlAroundCircle}
                                custom={{ i }}
                                // fillOpacity={0.5}
                                // style={{
                                //     fill: "black",
                                //     fillOpacity: 0.5
                                // }}
                                stroke="#000000" strokeMiterlimit="10"
                            />
                        ))}
                    </clipPath>
                </defs>

            </svg>
            {/* <div > */}
            <motion.img
                animate={ctrlImg}

                src={srcImg}
                // width="100%"
                // height="100%"
                style={{
                    objectPosition: '50% 50%',
                    // clipPath: 'url(#clipPath)',
                    clipPath: `url(#${clipPathId})`,
                    // position: 'absolute',
                    // objectFit: 'cover',
                    // height: "100%",
                    maxHeight: imgMaxHeight,
                    // width: '100%',
                    WebkitMaskImage: `url(${staticMaskImgPath})`,
                    maskImage: `url(${staticMaskImgPath})`,
                    maskSize: isMaskAnimation ? `${maskSizeWidthStart} ${maskSizeHeightStart}` : `${maskSizeWidthEnd} ${maskSizeHeightEnd}`,
                    maskPosition: '50%',
                    // maskImage: mask, //'radial-gradient(circle at 50% 50%, black 0%, black 25%, transparent 75%, transparent 100%)', // Apply the mask here
                    // // WebkitMaskImage: 'radial-gradient(circle at 50% 50%, transparent 0%, transparent 25%, black 75%, black 100%)', // For Safari
                    // maskPosition: '0%',
                    // backgroundSize: '1%',
                    // backgroundAttachment: 'fixed',
                    // objectFit: 'cover',
                    // position: 'absolute',
                    // width: '100%',
                    // height: '100%',
                    // top: 0,
                    // left: 0,
                    // right: 0,
                    // bottom: 0,
                    ...imgStyle,
                    height: imgHeight,

                }}
                loading="lazy"
            />
            {/* <motion.div
                    animate={ctrlImg}
                    style={{
                        clipPath: `url(#${clipPathId})`,
                        // maskImage: mask,
                        backgroundImage: 'radial-gradient(circle at 50% 50%, #770303 0%, #d30101 25%, transparent 75%, transparent 100%)',
                        WebkitMaskImage: `url(${staticMaskImgPath})`,
                        maskImage: `url(${staticMaskImgPath})`,
                        minHeight: divMinHeight,
                        backgroundSize: '1%',
                        backgroundAttachment: 'fixed',
                        // backgroundColor: "green",
                        // height: "200px",
                        // maxHeight: { maxHeight },
                        // width: '100%',
                        // // WebkitMaskImage: 'radial-gradient(circle at 50% 50%, transparent 0%, transparent 25%, black 75%, black 100%)', // For Safari
                    }}
                > */}

            {/* </motion.div> */}
            {/* </div> */}
        </div >
    )
}
export default ImageWithCircleAndCirclesAroundClipPathAnimation
